<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">hero with image background</div>
        <div class="module-preview--contents">
            <div class="image-wrapper">
                <img :src="this.getValue('image')" alt="">
            </div>
            <div class="info-wrapper self-center">
                <div class="font-600 text-lg">{{ getValue('headline') }}</div>
                <div class="font-500 text-base mt-1">{{ getValue('subHeadline') }}</div>
            </div>
        </div>
    </div>       
</template>

<script>
    import Widget from "@/mixins/Widget";
    import {mapGetters} from "vuex";

    export default {
        mixins: [ Widget ],
        computed: {
            ...mapGetters({
                getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId'
            })
        }
    }
</script>

<style scoped>   
    .module-preview--contents{
        grid-template-columns: 1.5fr 1fr;
    }
    .module-preview--contents .image-wrapper{
        height: 100%;
        max-height: 250px;
        width: 100%;
        overflow: hidden;
    } 
    .module-preview--contents .image-wrapper img{
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
    } 
</style>